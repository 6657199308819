@-webkit-keyframes notyf-fadeinup{
    0%{
        opacity:0;
        transform:translateY(25%)
    }
    to{
        opacity:1;
        transform:translateY(0)
    }
}
@keyframes notyf-fadeinup{
    0%{
        opacity:0;
        transform:translateY(25%)
    }
    to{
        opacity:1;
        transform:translateY(0)
    }
}
@-webkit-keyframes notyf-fadeinleft{
    0%{
        opacity:0;
        transform:translateX(25%)
    }
    to{
        opacity:1;
        transform:translateX(0)
    }
}
@keyframes notyf-fadeinleft{
    0%{
        opacity:0;
        transform:translateX(25%)
    }
    to{
        opacity:1;
        transform:translateX(0)
    }
}
@-webkit-keyframes notyf-fadeoutright{
    0%{
        opacity:1;
        transform:translateX(0)
    }
    to{
        opacity:0;
        transform:translateX(25%)
    }
}
@keyframes notyf-fadeoutright{
    0%{
        opacity:1;
        transform:translateX(0)
    }
    to{
        opacity:0;
        transform:translateX(25%)
    }
}
@-webkit-keyframes notyf-fadeoutdown{
    0%{
        opacity:1;
        transform:translateY(0)
    }
    to{
        opacity:0;
        transform:translateY(25%)
    }
}
@keyframes notyf-fadeoutdown{
    0%{
        opacity:1;
        transform:translateY(0)
    }
    to{
        opacity:0;
        transform:translateY(25%)
    }
}
@-webkit-keyframes ripple{
    0%{
        transform:scale(0) translateY(-45%) translateX(13%)
    }
    to{
        transform:scale(1) translateY(-45%) translateX(13%)
    }
}
@keyframes ripple{
    0%{
        transform:scale(0) translateY(-45%) translateX(13%)
    }
    to{
        transform:scale(1) translateY(-45%) translateX(13%)
    }
}
.notyf{
    position:fixed;
    top:0;
    right: 0;
    height:80%;
    margin: 5rem 1rem 0 1rem;
    width:100%;
    color:#fff;
    z-index:149;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    justify-content:flex-end;
    pointer-events:none;
    box-sizing:border-box;
    padding:20px
}
.notyf__icon--error{
    height:27px;
    width:27px;
    background:rgb(255, 0, 0, 0.6);
    border-radius:50%;
    display:block;
    margin:0 auto;
    position:relative
}
.notyf__icon--success{
    height:27px;
    width:27px;
    background:rgba(0, 255, 0, 0.6);
    border-radius:50%;
    display:block;
    margin:0 auto;
    position:relative  
}
.notyf__icon--error:after,.notyf__icon--error:before{
    content:"";
    background:currentColor;
    display:block;
    position:absolute;
    width:3px;
    border-radius:3px;
    left:12px;
    height:18px;
    top:5px
}
.notyf__icon--error:after{
    transform:rotate(-45deg)
}
.notyf__icon--error:before{
    transform:rotate(45deg)
}
.notyf__icon--success:after,.notyf__icon--success:before{
    content:"";
    background:currentColor;
    display:block;
    position:absolute;
    width:3px;
    border-radius:3px
}
.notyf__icon--success:after{
    height:10px;
    transform:rotate(-45deg);
    top:12px;
    left:7px
}
.notyf__icon--success:before{
    height:16px;
    transform:rotate(45deg);
    top:5px;
    left:15px
}
.notyf__toast{
    display:block;
    overflow:hidden;
    pointer-events:auto;
    -webkit-animation:notyf-fadeinup .3s ease-in forwards;
    animation:notyf-fadeinup .3s ease-in forwards;
    box-shadow:0 3px 7px 0 rgba(0,0,0,.25);
    border: solid 2px rgb(255, 165, 0, 0.4);
    background: linear-gradient(45deg,
    rgba(236, 236, 236, 0.3), rgba(0, 0, 0, 0.2));
    background-clip: padding-box;
    position:relative;
    padding:0 15px;
    border-radius:15px;
    max-width:300px;
    transform:translateY(25%);
    box-sizing:border-box;
    flex-shrink:0
}
.notyf__toast--disappear{
    transform:translateY(0);
    -webkit-animation:notyf-fadeoutdown .3s forwards;
    animation:notyf-fadeoutdown .3s forwards;
    -webkit-animation-delay:.25s;
    animation-delay:.25s
}
.notyf__toast--disappear .notyf__icon,.notyf__toast--disappear .notyf__message{
    -webkit-animation:notyf-fadeoutdown .3s forwards;
    animation:notyf-fadeoutdown .3s forwards;
    opacity:1;
    transform:translateY(0)
}
.notyf__toast--disappear .notyf__dismiss{
    -webkit-animation:notyf-fadeoutright .3s forwards;
    animation:notyf-fadeoutright .3s forwards;
    opacity:1;
    transform:translateX(0)
}
.notyf__toast--disappear .notyf__message{
    -webkit-animation-delay:.05s;
    animation-delay:.05s
}
.notyf__toast--upper{
    margin-bottom:20px
}
.notyf__toast--lower{
    margin-top:20px
}
.notyf__toast--dismissible .notyf__wrapper{
    padding-right:30px
}
.notyf__ripple{
    height:400px;
    width:400px;
    position:absolute;
    transform-origin:bottom right;
    right:0;
    top:0;
    border-radius:50%;
    transform:scale(0) translateY(-51%) translateX(13%);
    z-index:5;
    -webkit-animation:ripple .4s ease-out forwards;
    animation:ripple .4s ease-out forwards
}
.notyf__wrapper{
    display:flex;
    align-items:center;
    padding-top:17px;
    padding-bottom:17px;
    padding-right:15px;
    border-radius:3px;
    position:relative;
    z-index:10
}
.notyf__icon{
    width:22px;
    text-align:center;
    font-size:1.3em;
    opacity:0;
    -webkit-animation:notyf-fadeinup .3s forwards;
    animation:notyf-fadeinup .3s forwards;
    -webkit-animation-delay:.3s;
    animation-delay:.3s;
    margin-right:13px
}
.notyf__dismiss{
    position:absolute;
    top:0;
    right:0;
    height:100%;
    width:26px;
    margin-right:-15px;
    -webkit-animation:notyf-fadeinleft .3s forwards;
    animation:notyf-fadeinleft .3s forwards;
    -webkit-animation-delay:.35s;
    animation-delay:.35s;
    opacity:0
}
.notyf__dismiss-btn{
    background-color:rgba(0,0,0,.25);
    border:none;
    cursor:pointer;
    transition:opacity .2s ease,background-color .2s ease;
    outline:none;
    opacity:.35;
    height:100%;
    width:100%
}
.notyf__dismiss-btn:after,.notyf__dismiss-btn:before{
    content:"";
    background:rgb(255, 0, 0);
    height:16px;
    width:2px;
    border-radius:3px;
    position:absolute;
    left:calc(50% - 1px);
    top:calc(50% - 5px)
}
.notyf__dismiss-btn:after{
    transform:rotate(-45deg)
}
.notyf__dismiss-btn:before{
    transform:rotate(45deg)
}
.notyf__dismiss-btn:hover{
    opacity:.7;
    background-color:rgba(0,0,0,.15)
}
.notyf__dismiss-btn:active{
    opacity:.8
}
.notyf__message{
    vertical-align:middle;
    position:relative;
    opacity:0;
    color: rgba(250, 250, 250, 0.6);
    -webkit-animation:notyf-fadeinup .3s forwards;
    animation:notyf-fadeinup .3s forwards;
    -webkit-animation-delay:.25s;
    animation-delay:.25s;
    line-height:1.5em
}
@media only screen and (max-width:480px){
    .notyf{
        padding:0;
    }
    .notyf__ripple{
        height:600px;
        width:600px;
        -webkit-animation-duration:.5s;
        animation-duration:.5s
    }
    .notyf__toast{
        max-width:none;
        margin-top: 2px;
        border-radius:15px;
        box-shadow:0 -2px 7px 0 rgba(0,0,0,.13);
        width:90%
    }
    .notyf__dismiss{
        width:56px
    }
}
