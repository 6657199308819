@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    box-sizing: border-box;
    outline: none;
  }
  
  html,
  body,
  #root {
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
    background: radial-gradient(#383838, #000000);
    margin: 0;
    padding: 0;
    z-index: 1;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    font-family: 'Poppins', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  #canvas, .backdrop {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  
  #root:focus {
    outline: none;
  }

  .App {
    width: 100%;
    height: 100%;
    position: absolute;
    align-content: center;
    justify-content: center;
    margin: 0px;
}  

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.landing-pan {
  width: 16rem;
  height: 20rem;
  margin: 1rem;
  z-index: 99;
  transition: all 0.3s linear;
}

@media screen and (max-width: 480px) {
  .landing-pan {
      height: 20rem;
  }
}

.bot-card {
  width: 18rem;
  height: 30rem;
  margin: 1rem;
  z-index: 99;
  transition: all 0.3s linear;
}

@media screen and (max-width: 480px) {
  .bot-card {
      max-height: 30rem;
  }
}

.swap-card {
  display: flex;
  width: 40vw;
  height: 30rem;
  margin: 1rem;
  z-index: 99;
  transition: all 0.3s linear;
}

@media screen and (max-width: 800px) {
  .bot-card {
      width: 70vw;
      max-height: 30rem;
  }
}

.wrap {
  z-index: 200;
}

@media screen and (min-width: 800px) {
  .downloadCard {
    position: relative;
    margin-bottom: 20px;
    border-radius: 2em;
    width: 32rem;
    height: 8rem;
    transition: all 0.3s linear;
  }
}

text {
  font-size: 14px;
  font-weight: 600;
  fill: rgb(155, 155, 155);
}

.text01 {
  font-size: 14px;
  font-weight: 600;
  fill: rgba(102, 255, 0, 0.8);
}

@media screen and (max-width: 800px) {
  text, .text01 {
    font-size: 18px;
  }
}

.line {
  fill: none;
  stroke: rgba(255, 255, 255, 0.1);
  stroke-width: 2px;
}

.ball {
  fill: rgb(155, 155, 155);
}

.ball02 {
  fill: rgb(69, 172, 0);
}

footer {
  width:100vw;
  position:fixed;
  bottom:80px;
  z-index: 99;
}

.wavy {
  width:100%;
  overflow:visible;
}

.wave {
overflow:visible;
  animation: wave 3s linear;
  animation-iteration-count:infinite;
}

#wave2 {
  animation-duration:5s;
  animation-direction: reverse;
  opacity: 1;
}
#wave3 {
  animation-duration: 7s;
  opacity: 0.6;
}
@keyframes wave {
  to {transform: translateX(-100%);}
}